<template>
  <div class="header" :class="{ header_transparent: routerLink }">
    <app-button
      class="header__button_back"
      theme="gray"
      is-square
      @click="onReturnBack"
    >
      <img alt="" :src="require(`@/assets/icons/SVG/${icon}.svg`)" />
    </app-button>
    <app-button
      class="header__button_notification"
      theme="gray"
      is-square
      @click="onReturnBack"
    >
      <img alt="" src="@/assets/icons/SVG/notifications.svg" />
    </app-button>
    <app-button
      class="header__button_cart"
      theme="active"
      is-square
      @click="onReturnBack"
    >
      <img alt="" src="@/assets/icons/SVG/shopping-cart.svg" />
    </app-button>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'AppHeader',
  };
</script>

<script lang="ts" setup>
  import { ref } from 'vue';
  import router from '@/router';
  import AppButton from '@/components/atoms/AppButton.vue';

  const props = defineProps<{
    routerLink?: string;
  }>();

  const icon = ref(props.routerLink ? 'arrow' : 'menu');

  function onReturnBack() {
    if (props.routerLink) {
      router.push({
        name: props.routerLink,
      });
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    min-height: 75px;
    padding: 0 20px;

    transition: all 0.2 ease;

    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    background: white;

    &_transparent {
      background: transparent;
    }

    &__button_notification {
      margin-left: auto;
    }

    &__button_cart {
      margin-left: 15px;
    }
  }
</style>
