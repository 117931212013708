import { Visit } from '@/models';

const room = '01cbc698-ffd4-11ee-a7da-7bc76bdee00e';
const slugHotel = 'imperial-spb';

const visits: Visit[] = [
  {
    name: 'Palace',
    checkIn: new Date(2024, 2, 21),
    checkOut: new Date(2024, 2, 24),
    orders: [
      {
        name: 'СПА',
        count: '2 часа',
        cost: 1100,
        date: new Date(2024, 2, 21),
      },
      {
        name: 'Coca-Cola',
        count: '2шт',
        cost: 0,
        date: new Date(2024, 2, 21),
      },
      {
        name: 'СПА',
        count: '2 часа',
        cost: 1100,
        date: new Date(2024, 2, 22),
      },
      {
        name: 'Coca-Cola',
        count: '2шт',
        cost: 250,
        date: new Date(2024, 2, 22),
      },
    ],
  },
  {
    name: 'Мрия',
    checkIn: new Date(2024, 2, 21),
    checkOut: new Date(2024, 2, 24),
    orders: [],
  },
  {
    name: 'Ялта Интурист',
    checkIn: new Date(2024, 2, 21),
    checkOut: new Date(2024, 2, 24),
    orders: [
      {
        name: 'СПА',
        count: '2 часа',
        cost: 1100,
        date: new Date(2024, 2, 21),
      },
      {
        name: 'Coca-Cola',
        count: '2шт',
        cost: 0,
        date: new Date(2024, 2, 21),
      },
      {
        name: 'СПА',
        count: '2 часа',
        cost: 1100,
        date: new Date(2024, 2, 22),
      },
      {
        name: 'Coca-Cola',
        count: '2шт',
        cost: 250,
        date: new Date(2024, 2, 22),
      },
    ],
  },
];

const spa = {
  categories: [
    {
      name: 'Спа программы по телу',
      image: 'spa1',
      count: 3,
    },
    {
      name: 'Спа программы по телу',
      image: 'spa2',
      count: 3,
    },
    {
      name: 'Спа программы по телу',
      image: 'spa7',
      count: 3,
    },
    {
      name: 'Спа программы по телу',
      image: 'spa3',
      count: 3,
    },
    {
      name: 'Спа программы по телу',
      image: 'spa4',
      count: 3,
    },
    {
      name: 'Спа программы по телу',
      image: 'spa5',
      count: 3,
    },
    {
      name: 'Спа программы по телу',
      image: 'spa6',
      count: 3,
    },
  ],
  services: [],
};

const massage = {
  categories: [],
  services: [
    {
      name: 'Массаж релакс',
      description:
        'Данная массажная техника направлена на устранение последствий стресса, снятие мышечного напряжения и улучшение общего самочувствия.',
      image: 'spa7',
      logo: 'spa7',
      price: 3500,
    },
    {
      name: 'Массаж релакс',
      description:
        'Данная массажная техника направлена на устранение последствий стресса, снятие мышечного напряжения и улучшение общего самочувствия.',
      image: 'spa7',
      logo: 'spa7',
      price: 3500,
    },
    {
      name: 'Массаж релакс',
      description:
        'Данная массажная техника направлена на устранение последствий стресса, снятие мышечного напряжения и улучшение общего самочувствия.',
      image: 'spa7',
      logo: 'spa7',
      price: 3500,
    },
    {
      name: 'Массаж релакс',
      description:
        'Данная массажная техника направлена на устранение последствий стресса, снятие мышечного напряжения и улучшение общего самочувствия.',
      image: 'spa7',
      logo: 'spa7',
      price: 3500,
    },
    {
      name: 'Массаж релакс',
      description:
        'Данная массажная техника направлена на устранение последствий стресса, снятие мышечного напряжения и улучшение общего самочувствия.',
      image: 'spa7',
      logo: 'spa7',
      price: 3500,
    },
    {
      name: 'Массаж релакс',
      description:
        'Данная массажная техника направлена на устранение последствий стресса, снятие мышечного напряжения и улучшение общего самочувствия.',
      image: 'spa7',
      logo: 'spa7',
      price: 3500,
    },
    {
      name: 'Массаж релакс',
      description:
        'Данная массажная техника направлена на устранение последствий стресса, снятие мышечного напряжения и улучшение общего самочувствия.',
      image: 'spa7',
      logo: 'spa7',
      price: 3500,
    },
    {
      name: 'Массаж релакс',
      description:
        'Данная массажная техника направлена на устранение последствий стресса, снятие мышечного напряжения и улучшение общего самочувствия.',
      image: 'spa7',
      logo: 'spa7',
      price: 3500,
    },
  ],
};

const restaurants = {
  categories: [
    {
      name: 'Шведсткий стол',
      image: 'restaurant1',
      count: 0,
    },
    {
      name: 'Столовая',
      image: 'restaurant2',
      count: 0,
    },
  ],
  services: [
    {
      name: 'Ресторан "SAVOY"',
      description: 'Хороший ресторан',
      image: 'restaurant',
      logo: 'savoy',
      price: 0,
    },
  ],
};

const transfer = {
  categories: [],
  services: [
    {
      name: 'BMW',
      description: 'Люксовый седан',
      image: 'bmw',
      logo: 'bmw-logo',
      price: 5000,
    },
  ],
};

const conferenceRoom = {
  categories: [],
  services: [
    {
      name: 'Конференц-зал',
      description: 'Конференц-залище',
      image: 'conference',
      logo: 'conference',
      price: 3000,
    },
  ],
};

const categories = [
  {
    id: 'spa',
    name: 'SPA',
  },
  {
    id: 'accessories',
    name: 'Доставка в номер',
  },
  {
    id: 'restaurants',
    name: 'Рестораны',
  },
  {
    id: 'transfer',
    name: 'Трансфер',
  },
  {
    id: 'conference',
    name: 'Конференц-залище',
  },
];

const subcategories = [
  {
    id: 'spa',
    name: 'SPA',
  },
  {
    id: 'massage-zone',
    name: 'Массаж отдельных зон',
  },
  {
    id: 'massage',
    name: 'Массажик',
  },
];

export {
  room,
  slugHotel,
  visits,
  spa,
  restaurants,
  conferenceRoom,
  transfer,
  categories,
  subcategories,
  massage,
};
