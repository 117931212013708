<template>
  <div>
    <div class="history-page__visits">
      <div class="history-page__visit visit">
        <div class="visit__inner">
          <div class="visit__icon skeleton-circle"></div>
          <div class="visit__info">
            <div class="visit__name skeleton-text"></div>
            <div class="visit__date skeleton-text"></div>
          </div>
        </div>
        <hr class="visit__divider divider" />
      </div>
      <div class="history-page__visit visit">
        <div class="visit__inner">
          <div class="visit__icon skeleton-circle"></div>
          <div class="visit__info">
            <div class="visit__name skeleton-text"></div>
            <div class="visit__date skeleton-text"></div>
          </div>
        </div>
        <hr class="visit__divider divider" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .history-page {
    &__visits {
      width: 100%;
    }

    .visit {
      &__inner {
        display: flex;
        align-items: center;

        width: 100%;
        padding: 15px 0;
      }

      &__icon.skeleton-circle {
        animation: pulse 2s infinite;

        border-radius: 10px;
        background: var(--blue-bg);

        @include setSquareSize(52px);
      }

      &__info {
        width: 75%;
        margin-left: 15px;
      }

      &__name.skeleton-text,
      &__date.skeleton-text {
        width: 70%;
        height: 20px;

        animation: pulse 2s infinite;

        border-radius: 4px;
        background-color: var(--blue-bg);
      }

      &__date.skeleton-text {
        width: 50%;
        margin-top: 5px;
      }

      &__divider {
        position: absolute;
        left: 0;

        width: 100%;
        height: 1px;

        background-color: var(--divider-color);
      }
    }

    &__icon.skeleton-circle {
      margin: 50px auto;

      animation: pulse 2s infinite;

      border-radius: 50%;
      background: var(--blue-bg);

      @include setSquareSize(110px);
    }

    &__text.skeleton-text {
      width: 70%;
      height: 20px;

      animation: pulse 2s infinite;

      border-radius: 4px;
      background-color: var(--blue-bg);
    }
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
</style>
