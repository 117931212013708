<template>
  <div class="wrapper history-page">
    <div class="history-page__header">
      <div class="history-page__logo"></div>
      <div class="history-page__logo_big"></div>
      <app-button
        class="history-page__button"
        text="Выйти"
        theme="common"
        @click="onLogout"
      >
        <img
          alt=""
          src="@/assets/icons/SVG/arrow-circle-broken-left.svg"
          class="button__icon"
        />
      </app-button>
    </div>
    <div class="history-page__body">
      <div class="history-page__title">История посещений</div>
      <Suspense>
        <template #default>
          <history-visits />
        </template>
        <template #fallback>
          <history-visits-skeleton />
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { HistoryVisits, HistoryVisitsSkeleton } from '..';
  import router from '@/router';
  import { useAuthStore } from '@/stores/auth';

  const store = useAuthStore();

  async function onLogout(index: number) {
    await store.logout().then(() => {
      router.push({
        name: 'WelcomePage',
        params: { id: index },
      });
    });
  }
</script>

<style lang="scss" scoped>
  .history-page {
    align-items: center;
    flex-direction: column;

    &__title {
      margin: 25px 0;

      @include subtitle-text;
    }

    &__header {
      display: flex;
      align-items: center;

      width: 100%;
      padding: 8px;
    }

    &__logo {
      min-height: 50px;

      background: url('@/assets/images/logo.png') no-repeat center / cover;

      @include setSquareSize(50px);

      &_big {
        width: 100px;
        height: 20px;
        margin-left: 12px;

        background: url('@/assets/icons/SVG/logo-hotelkey.svg') no-repeat center /
          cover;
      }
    }

    &__button {
      width: 127px;
      margin-top: 6px;
      margin-left: auto;

      .button__icon {
        rotate: 180deg;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 50px auto;

      border-radius: 10px;
      background: var(--blue-bg);

      @include setSquareSize(110px);
    }

    &__text {
      text-align: center;

      color: var(--gray-black);

      line-height: 22px;

      @include toRem(font-size, 18px);
    }

    &__body {
      width: 100%;
    }
  }
</style>
