<template>
  <div>
    <div v-if="hotels.length" class="history-page__visits">
      <div
        v-for="(visit, index) in hotels"
        :key="index"
        class="history-page__visit visit"
        @click="onCheckOrders(index)"
      >
        <div class="visit__inner">
          <div class="visit__icon"></div>
          <div class="visit__info">
            <div class="visit__name">{{ visit.name }}</div>
            <div class="visit__date">
              {{ visit.checkIn.toLocaleDateString() }}-{{
                visit.checkOut.toLocaleDateString()
              }}
            </div>
          </div>
          <div class="visit__button">
            <img src="@/assets/icons/SVG/arrow-narrow-left.svg" alt="" />
          </div>
        </div>
        <hr class="visit__divider divider" />
      </div>
    </div>
    <div v-else class="history-page__block">
      <div class="history-page__icon">
        <img
          src="@/assets/icons/SVG/face-frown.svg"
          alt=""
          class="welcome-page__image"
        />
      </div>
      <div class="history-page__text">
        Вы еще не пользовались нашим приложением
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { visits } from '@/config';
  import { Visit } from '@/models';
  import router from '@/router';

  const hotelVisits = ref(async () => {
    return new Promise<Visit[]>(resolve => {
      setTimeout(() => {
        resolve(visits);
      }, 5000);
    });
  });

  const hotels = ref(await hotelVisits.value());

  function onCheckOrders(index: number) {
    router.push({
      name: 'HistoryOrderPage',
      params: { id: index },
    });
  }
</script>

<style lang="scss" scoped>
  .history-page {
    &__visits {
      width: 100%;
    }

    .visit {
      &__inner {
        display: flex;
        align-items: center;

        width: 100%;
        padding: 15px 0;
      }

      &__icon {
        border-radius: 10px;
        background: var(--blue-bg);

        @include setSquareSize(52px);
      }

      &__info {
        margin-left: 15px;
      }

      &__name {
        font: {
          @include toRem(size, 20px);
          weight: 600;
        }

        @include toRem(line-height, 24.3px);
      }

      &__date {
        margin-top: 5px;

        color: var(--gray-black);
      }

      &__button {
        margin-left: auto;

        @include filter-blue;

        img {
          @include setSquareSize(24px);
        }
      }

      &__divider {
        position: absolute;
        left: 0;
      }
    }
  }
</style>
