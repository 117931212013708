<template>
  <div class="wrapper template-auth">
    <div
      :class="{
        'template-auth__block': true,
        block: true,
        'template-auth__block_small': isSmall,
      }"
    >
      <div class="template-auth__header">
        <div v-if="isLogoVisible" class="template-auth__logo"></div>
        <div class="template-auth__logo_big"></div>
        <app-button
          theme="inactive"
          is-square
          is-round
          class="template-auth__button"
          @click="openInfoModal(true)"
        >
          <img src="@/assets/icons/SVG/help-info.svg" alt="" />
        </app-button>
      </div>
      <h1 class="template-auth__title">{{ title }}</h1>
      <slot></slot>
    </div>
    <slot name="buttons"></slot>
    <transition name="modal" mode="out-in" :duration="550">
      <auth-modal
        :is-show-modal="isModalVisible"
        @close="openInfoModal(false)"
      />
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, onUnmounted, ref } from 'vue';
  import { AuthModal } from '.';
  import { isPWA, isSafari } from '@/helper';

  const isScroll = ref(false);
  const isModalVisible = ref(false);

  withDefaults(
    defineProps<{
      title?: string;
      isSmall?: boolean;
      isLogoVisible?: boolean;
    }>(),
    {
      title: 'Войдите в приложение',
      isSmall: false,
      isLogoVisible: false,
    },
  );

  function setWindowHeight(event?: Event) {
    let vh: number;
    if (!event) vh = window.innerHeight * 0.01;
    else {
      vh = event.target?.height * 0.01;
    }
    const page = document.querySelector<HTMLElement>('.template-auth');
    const buttonScroll = document.querySelector<HTMLElement>('.scroll');
    const isKeyboardOpen =
      parseFloat(document.documentElement.style.getPropertyValue('--height')) >
      vh;
    if (page) page.style.setProperty('height', `${vh * 100}px`, 'important');
    if (isKeyboardOpen && !isScroll.value) {
      setTimeout(
        () =>
          buttonScroll?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          }),
        300,
      );
      isScroll.value = true;
    } else if (!isKeyboardOpen) isScroll.value = false;
    return vh;
  }

  function openInfoModal(value: boolean): void {
    isModalVisible.value = value;
  }

  onMounted(async () => {
    setWindowHeight();
    if (isSafari() && !isPWA()) setInterval(setWindowHeight, 100);
    else visualViewport?.addEventListener('resize', setWindowHeight);
  });

  onUnmounted(() => {
    visualViewport?.removeEventListener('resize', setWindowHeight);
  });
</script>

<style lang="scss">
  .template-auth {
    position: relative;
    z-index: 1000;

    align-items: center;
    flex-direction: column;

    min-height: 435px !important;
    padding: 30px 0 !important;

    .scroll {
      padding-bottom: 50px;
    }

    &__header {
      display: flex;
      align-items: center;

      width: 100%;
      padding: 3px;
    }

    &__logo {
      min-height: 60px;

      background: url('@/assets/images/logo.png') no-repeat center / cover;

      @include setSquareSize(60px);

      &_big {
        position: absolute;
        left: 83px;

        width: 115px;
        height: 23px;

        background: url('@/assets/icons/SVG/logo-hotelkey.svg') no-repeat center /
          cover;
      }
    }

    &__button {
      margin-top: 6px;
      margin-left: auto;
    }

    &__block {
      position: absolute;
      top: 0;

      display: flex;
      align-items: center;
      flex-direction: column;

      width: calc(100% + 20px);
      height: 300px;
      padding: 8px;

      border-radius: 0px 0px 30px 30px;
      background-color: white;
      box-shadow: 0px 0px 14.3px 0px #00000021;

      &_small {
        height: 220px !important;
      }
    }

    &__title {
      margin-top: 30px;

      font: {
        weight: 700;
        @include toRem(size, 20px);
      }
    }

    .text {
      margin-top: 347px;

      text-align: center;

      color: var(--gray-disable);

      @include toRem(line-height, 22px);

      &_resend {
        margin: 0;

        text-align: center;

        color: var(--gray-disable);

        font-weight: 600;

        @include toRem(line-height, 22px);
      }
    }

    .link_close {
      position: absolute;
      top: 15px;
      left: 8px;

      display: block;
      align-self: flex-end;
    }

    .button {
      &__icon {
        @include filter-white;
      }

      &_close {
        transform: rotate(180deg);

        border-radius: 0;
        background: transparent;

        @include setSquareSize(48px);
        filter: brightness(0) saturate(100%);
      }

      &_send {
        position: absolute;
        top: 355px;

        width: 100%;

        transition: all 0.1s ease;
      }

      &_bottom {
        margin-top: 30px;

        font-weight: 600;

        @include toRem(line-height, 22px);
      }

      &_phone {
        margin-top: 5px;

        color: var(--blue-main);

        font-weight: 700;
      }

      &_resend {
        display: flex;

        margin-top: 18px;

        text-align: center;

        color: var(--gray-disable);

        font-weight: 600;

        gap: 5px;
        @include toRem(line-height, 22px);

        &_active {
          color: var(--blue-main);

          .icon_resend {
            @include filter-blue;
          }
        }
      }
    }

    .icon_close {
      @include setSquareSize(26px);
    }

    .input-tile {
      margin: 25px 0 0;
      padding: 0 4px;
    }

    .modal-enter-active,
    .modal-leave-active {
      transition: all 0.2s ease-in;
    }

    .modal-leave-active {
      transition-delay: 0.15s;
    }

    .modal-enter-from,
    .modal-leave-to {
      opacity: 0;
    }

    .modal-enter-active .modal,
    .modal-leave-active .modal {
      transition: all 0.2s ease-out;
    }

    .modal-enter-active .modal {
      transition-delay: 0.15s;
    }

    .modal-enter-from .modal,
    .modal-leave-to .modal {
      transform: translateY(300px);
    }
  }
</style>
