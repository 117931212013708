<template>
  <app-modal
    v-if="isShowModal"
    class="services-modal"
    is-rounded
    @close="onCloseModal"
  >
    <div class="services-modal__block">
      <img
        src="@/assets/images/spa7.png"
        alt=""
        class="services-modal__image"
      />
      <span class="services-modal__title">
        {{ service?.translations.ru_RU.name }}
      </span>
    </div>
    <span class="services-modal__description">
      {{ service?.translations.ru_RU.description }}
    </span>
    <div class="services-modal__rate rate">
      <img
        v-if="service?.meta?.duration"
        alt=""
        src="@/assets/icons/SVG/timer.svg"
      />
      <div v-if="service?.meta?.duration" class="rate__value">
        {{ service?.meta?.duration }}
      </div>
      <div class="rate__icon">₽</div>
      <div class="rate__value">{{ service?.price }} руб</div>
    </div>
    <div class="services-modal__date">Выберите дату и время</div>
    <DatePicker
      v-model="date"
      inline
      :class="{ 'p-datepicker-error': isError }"
    />
    <transition name="modal" mode="out-in" :duration="550">
      <div v-if="date" class="services-modal__date">
        Свободное время на
        <span>
          {{
            date.toLocaleString('default', {
              day: 'numeric',
              month: 'long',
            })
          }}
        </span>
      </div>
    </transition>
    <transition name="modal" mode="out-in" :duration="550">
      <div v-if="date" class="services-modal__time">
        <img src="@/assets/icons/SVG/clock.svg" alt="" />
        <app-button
          v-for="time in freeTime"
          :key="time"
          class="services-modal__button"
          :theme="currentTime === time ? 'active' : 'inactive'"
          :text="time"
          @click="onChangeSize(time)"
        />
      </div>
    </transition>
    <app-button
      class="services-modal__book"
      is-disable
      text="Забронировать"
      data-cy="invite"
    >
      <img alt="" src="@/assets/icons/SVG/check-white-icon.svg" />
    </app-button>
    <app-message v-if="isError" class="services-modal__message" is-error>
      Вы не выбрали дату!
    </app-message>
  </app-modal>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { IService } from '@/models';
  import DatePicker from 'primevue/datepicker';

  defineProps<{
    isShowModal: boolean;
    service: IService | null;
  }>();

  const emits = defineEmits<{
    close: [];
  }>();

  const date = ref<Date | null>(null);
  const freeTime = ref(['12:00', '13:00', '17:00', '21:00']);
  const currentTime = ref('');
  const isError = ref(false);
  const isLoading = ref(false);
  const isOffline = ref(false);

  function onCloseModal() {
    emits('close');
  }

  function onChangeSize(time: string): void {
    currentTime.value = time;
  }

  onMounted(() => {
    if (!navigator.onLine) {
      isLoading.value = true;
      isError.value = true;
      isOffline.value = true;
    }
    window.addEventListener('offline', () => {
      isLoading.value = true;
      isError.value = true;
      isOffline.value = true;
    });
    window.addEventListener('online', () => {
      isLoading.value = false;
      isError.value = false;
      isOffline.value = false;
    });
  });
</script>

<style lang="scss" scoped>
  .services-modal {
    z-index: 1002;
    &__icon {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;

      border-radius: 10px;
      background: white;

      @include setSquareSize(52px);
    }

    &__description {
      width: 100%;
      margin-bottom: 15px;
      padding: 0 8px;

      color: var(--gray-black);
    }

    &__image {
      overflow: hidden;

      border-radius: 50%;

      @include setSquareSize(60px);
      object-fit: cover;
    }

    &__block {
      display: flex;
      align-items: center;

      width: 100%;
      margin-bottom: 15px;
      padding: 0 8px;

      font: {
        weight: 600;
        @include toRem(size, 20px);
      }

      gap: 15px;
      @include toRem(line-height, 24.3px);
    }

    &__title {
      width: calc(100% - 142px);
    }

    &__book {
      z-index: 100;

      width: 100%;
      margin: 30px 0 50px;
    }

    &__date {
      width: 100%;
      padding: 18px 0;

      color: var(--gray-black);

      font: {
        weight: 600;
        @include toRem(size, 18px);
      }

      span {
        color: var(--blue-main);
      }
    }

    &__rate.rate {
      display: flex;
      align-items: center;

      width: 100%;
      height: 70px;
      padding: 24px 20px;

      border-radius: 30px;
      background: var(--light-gray);

      gap: 10px;

      img {
        @include setSquareSize(23px);
      }
    }

    &__time {
      display: flex;

      gap: 5px;

      img {
        @include filter-gray;
      }
    }

    &__button {
      height: 33px;
      padding: 0 15px;

      color: black;
      background: white;

      @include toRem(font-size, 15px);
      @include toRem(line-height, 18px);

      &.on-hover {
        background: var(--light-gray);
      }
    }

    &__message {
      position: absolute;
      bottom: 32px;
    }

    .rate {
      &__icon {
        margin-left: 5px;

        text-align: center;

        color: white;
        border-radius: 50%;
        background: var(--blue-main);

        font-family: 'Montserrat', 'Proxima Nova', sans-serif;
        font-size: 16px;
        line-height: 23px;

        @include setSquareSize(23px);
      }

      &__value {
        font-weight: 600;
      }
    }
  }
</style>
<style lang="scss">
  .p-datepicker {
    width: 100%;

    border: 1px solid transparent;
    border-radius: 30px;
  }

  .p-datepicker-error {
    border-color: var(--red-error);
    border-radius: 30px;
  }

  .p-datepicker-panel.p-component {
    padding: 0;

    border: none;
    border-radius: 30px;
    background: var(--light-gray);

    .p-datepicker-header {
      padding: 10px 25px 10px 15px;

      border-bottom: 2px solid #0000001a;
      background: var(--light-gray);

      font-weight: 600;
    }

    .p-datepicker-weekday {
      font-weight: 600;
    }
    .p-datepicker-title {
      color: black;

      font-weight: 600;

      gap: 5px;
    }

    .p-datepicker-select-month,
    .p-datepicker-select-year {
      padding: 0;
    }

    .p-datepicker-month-selected,
    .p-datepicker-year-selected {
      background: var(--blue-main);
    }

    .p-datepicker-day-selected {
      background: var(--blue-main) !important;
    }

    .p-datepicker-day-selected:focus-visible {
      outline: none;
      box-shadow: none;
    }

    .p-datepicker-today > .p-datepicker-day {
      background: var(--gray-main);
    }

    .p-datepicker-day-cell {
      padding: 0.05rem;
    }

    .p-button-text.p-button-secondary {
      border: 1px solid var(--gray-main);
      border-radius: 50px;
      background: white;
    }

    .p-datepicker-day-view {
      width: calc(100% - 24px);
      margin: 12px;
    }

    .p-button-icon {
      @include filter-blue;
    }
  }
</style>
