<template>
  <swiper-container
    ref="navbar"
    class="swiper"
    slides-per-view="auto"
    :space-between="5"
    :free-mode="true"
  >
    <swiper-slide v-for="item in categories" :key="item.slug" class="slide">
      <a :href="`#${item.slug}`">
        <span>{{ item.translations.ru_RU.name }}</span>
      </a>
    </swiper-slide>
    <swiper-slide v-if="isHaveAccessories" key="accessories" class="slide">
      <a href="#accessories">
        <span>Доставка принадлежностей</span>
      </a>
    </swiper-slide>
  </swiper-container>
</template>

<script lang="ts" setup>
  import { onMounted, onUnmounted, ref } from 'vue';
  import { register } from 'swiper/element/bundle';
  import { Category } from '@/models';
  import { isPWA, isSafari } from '@/helper';

  const props = defineProps<{
    categories: Category[];
    isHaveAccessories?: boolean;
    isOpenModal: boolean;
  }>();

  register();
  const navbar = ref<HTMLElement | null>(null);
  const inViewAnchor = ref<HTMLElement | null>(null);
  const isTop = ref(false);
  const isAnimation = ref(false);
  const isHeaderTransparent = ref(false);

  function isElementInViewport(el: HTMLElement | Element) {
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.top <= 140;
  }

  function isSecondToLastElementInViewport(el: HTMLElement | Element) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const isAsBottom = scrollTop + windowHeight >= documentHeight - 150;

    return isElementInViewport(el) || isAsBottom;
  }

  function isLastElementInViewport(el: HTMLElement | Element) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const isAsBottom = scrollTop + windowHeight >= documentHeight - 100;

    return isElementInViewport(el) || isAsBottom;
  }

  function checkVisibleSections() {
    if (!inViewAnchor.value) {
      const anchor: HTMLElement | null = document.querySelectorAll('a')[0];
      if (anchor) changeViewAnchor(anchor, 0);
    }
    const sections = document.querySelectorAll('.section');
    const lastSection = sections[sections.length - 1] as HTMLElement;
    const isLastElementView = isLastElementInViewport(lastSection);
    const lastAnchor: HTMLElement | null = document.querySelector(
      `a[href="#${lastSection.getAttribute('id')}"]`,
    );
    if (
      !props.isOpenModal &&
      !isAnimation.value &&
      isLastElementView &&
      inViewAnchor.value !== lastAnchor &&
      lastAnchor
    ) {
      changeViewAnchor(lastAnchor, sections.length - 1);
      return;
    } else if (isLastElementView) return;

    sections.forEach((section, index) => {
      const sectionId = section.getAttribute('id');
      const anchor: HTMLElement | null = document.querySelector(
        `a[href="#${sectionId}"]`,
      );
      if (
        !props.isOpenModal &&
        !isAnimation.value &&
        anchor &&
        inViewAnchor.value !== anchor
      ) {
        if (
          (isSecondToLastElementInViewport(section) &&
            index === sections.length - 2) ||
          isElementInViewport(section) ||
          (isTop.value && index === 0)
        ) {
          changeViewAnchor(anchor, index);
        }
      }
    });
  }

  function changeViewAnchor(currentAnchor: HTMLElement, index: number) {
    const swiperEl = document.querySelector('swiper-container');
    inViewAnchor.value = currentAnchor;
    swiperEl?.swiper.slideTo(index, 500);
    if (inViewAnchor.value) {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.classList.remove('in-view');
      });
      inViewAnchor.value.classList.add('in-view');
    }
  }

  function changeHeaderView() {
    const header: HTMLElement | null = document.querySelector('.header');
    const main: HTMLElement | null = document.querySelector('.main');
    if (!navbar.value || !header || !main) return;

    const elementRect = navbar.value.getBoundingClientRect();
    const mainRect = main.getBoundingClientRect();
    const threshold = 75;

    if (elementRect.top <= threshold) {
      if (elementRect.bottom <= mainRect.top) {
        isTop.value = true;
        navbar.value.style.position = 'absolute';
        navbar.value.style.top = '';
        navbar.value.style.left = '-20px';
        navbar.value.style.overflow = 'visible';
        navbar.value.style.width = 'calc(100% + 40px)';
        header.style.borderBottomRightRadius = '30px';
        header.style.borderBottomLeftRadius = '30px';
        if (isHeaderTransparent.value)
          header.classList.add('header_transparent');
      } else {
        isTop.value = false;
        navbar.value.style.position = 'fixed';
        navbar.value.style.top = `${threshold}px`;
        navbar.value.style.left = '0';
        navbar.value.style.overflow = 'hidden';
        navbar.value.style.width = '100%';
        header.style.borderBottomRightRadius = '0px';
        header.style.borderBottomLeftRadius = '0px';
        if (isHeaderTransparent.value)
          header.classList.remove('header_transparent');
      }
    }
  }
  function scrollToElementTop(elem: HTMLElement, offset: number) {
    offset = offset || 0;

    const rect = elem.getBoundingClientRect();
    const targetPosition = rect.top + window.pageYOffset + offset;

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
  }

  onMounted(() => {
    if (navbar.value) {
      navbar.value.style.position = 'absolute';
      navbar.value.style.width = 'calc(100% + 40px)';
    }
    const header: HTMLElement | null = document.querySelector('.header');
    if (header)
      isHeaderTransparent.value =
        header?.classList.contains('header_transparent');

    document
      .querySelector('.swiper')
      ?.shadowRoot?.querySelector('.swiper')
      ?.setAttribute('style', 'overflow:visible');
    document.querySelectorAll('a[href^="#"]').forEach((anchor, index) => {
      anchor.addEventListener('click', function (e) {
        isAnimation.value = true;
        e.preventDefault();
        const element = document.querySelector(this.getAttribute('href'));

        changeViewAnchor(this, index);
        scrollToElementTop(element, -130);
        checkVisibleSections();
      });
    });
    if (isSafari() && !isPWA()) {
      setInterval(changeHeaderView, 100);
      window.addEventListener('scroll', checkVisibleSections);
    } else {
      window.addEventListener('scroll', changeHeaderView);
      window.addEventListener('scroll', checkVisibleSections);
      window.addEventListener('resize', checkVisibleSections);
    }
    window.addEventListener('touchstart', () => {
      isAnimation.value = false;
    });
    checkVisibleSections();
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', changeHeaderView);
    window.removeEventListener('scroll', checkVisibleSections);
    window.removeEventListener('resize', checkVisibleSections);
  });
</script>

<style lang="scss" scoped>
  .swiper {
    position: absolute;
    z-index: 1001;
    bottom: -15px;
    left: -20px;

    overflow: visible;

    width: calc(100% + 40px);
    height: 60px;
    padding: 0 20px;

    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    background: white;

    a {
      display: block;

      width: 100%;
      height: 100%;
      padding: 9px 20px;

      color: var(--gray-black);

      @include none-select;
    }

    a.in-view {
      color: white;
      background: var(--blue-main);
    }

    .slide {
      overflow: hidden;

      width: auto !important;
      height: min-content;

      border-radius: 48px;
      background: #d9d9d95c;

      font: {
        @include toRem(size, 18px);
        weight: 600;
      }

      @include toRem(line-height, 22px);
    }
  }
</style>
