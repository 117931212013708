import { createRouter, createWebHistory } from 'vue-router';
import {
  ServiceItemPage,
  ServiceConfirmPage,
  CategoryPage,
  ServicePage,
  InviteCodePage,
  CodeEmailPage,
  PhoneConfirmPage,
  HistoryOrderPage,
  OnboardingPage,
  HotelPage,
  ServicesPage,
} from '@/pages';
import {
  CodePageController,
  CodePhoneController,
  WelcomePageController,
} from '@/controllers';

const routes = [
  {
    path: '/',
    name: 'WelcomePage',
    component: WelcomePageController,
  },
  {
    path: '/onboard',
    name: 'OnboardPage',
    component: OnboardingPage,
  },
  {
    path: '/hotel/:slug',
    name: 'HotelPage',
    component: HotelPage,
  },
  {
    path: '/hotel/:slug/:slugCategory',
    name: 'ServicesPage',
    component: ServicesPage,
  },
  {
    path: '/history/:id',
    name: 'HistoryOrderPage',
    component: HistoryOrderPage,
  },
  {
    path: '/auth/sms',
    name: 'CodePage',
    component: CodePageController,
  },
  {
    path: '/auth/phone',
    name: 'CodePhonePage',
    component: CodePhoneController,
  },
  {
    path: '/auth/email',
    name: 'CodeEmailPage',
    component: CodeEmailPage,
  },
  {
    path: '/auth/phone/confirm',
    name: 'PhoneConfirmPage',
    component: PhoneConfirmPage,
  },
  {
    path: '/auth/invite',
    name: 'InviteCodePage',
    component: InviteCodePage,
  },
  {
    path: '/category',
    name: 'CategoryPage',
    component: CategoryPage,
  },
  {
    path: '/category/:id',
    name: 'SubCategoryPage',
    component: CategoryPage,
  },
  {
    path: '/category/:id/:service',
    name: 'ServicePage',
    component: ServicePage,
  },
  {
    path: '/category/:id/:service/service',
    name: 'ServiceItemPage',
    component: ServiceItemPage,
  },
  {
    path: '/category/:id/:service/service/confirm',
    name: 'ServiceConfirmPage',
    component: ServiceConfirmPage,
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

export default router;
