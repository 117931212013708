<template>
  <div
    :class="{
      notification_error: isError,
      notification_correct: isCorrect,
    }"
    class="notification"
  >
    <div
      :class="{
        notification__inner_error: isError,
        notification__inner_correct: isCorrect,
      }"
      class="notification__inner"
    >
      <div
        :class="{
          notification__icon_error: isError,
          notification__icon_correct: isCorrect,
        }"
        class="notification__icon"
      ></div>
    </div>
    <span
      :class="{
        notification__text_error: isError,
        notification__text_correct: isCorrect,
      }"
      class="notification__text"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'PopupNotification',
  };
</script>

<script lang="ts" setup>
  withDefaults(
    defineProps<{
      isError: boolean;
      isCorrect: boolean;
    }>(),
    {
      isError: false,
      isCorrect: false,
    },
  );
</script>

<style lang="scss" scoped>
  .notification {
    position: fixed;
    z-index: 10001;
    top: 50px;
    left: 20px;

    display: flex;
    align-items: center;
    align-self: flex-start;

    width: calc(100% - 40px);
    height: 55px;
    padding: 0 5px;

    border-radius: 47px;

    font: {
      @include toRem(size, 16px);
      weight: 600;
    }

    gap: 35px;

    &_error {
      color: var(--red-error);
      background: var(--light-red);
    }

    &_correct {
      color: var(--blue-main);
      background: white;
    }

    &__text {
      padding-top: 2px;
    }

    &__inner {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;

      border-radius: 50%;

      @include setSquareSize(42px);

      &_error {
        background: #ffe2e2;
      }

      &_correct {
        background: #1137ff2e;
      }
    }

    &__icon {
      width: 100%;
      height: 100%;

      &_error {
        background: url('@/assets/icons/SVG/error-icon.svg') no-repeat center;
      }

      &_correct {
        background: url('@/assets/icons/SVG/check-icon.svg') no-repeat center;
      }
    }
  }
</style>
