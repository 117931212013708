<template>
  <div class="wrapper order-page">
    <app-header router-link="WelcomePage">История заказов</app-header>
    <div class="order-page__block">
      <div class="order-page__cover">
        <img
          src="@/assets/icons/SVG/picture.svg"
          alt=""
          class="order-page__picture"
        />
      </div>
      <div class="order-page__name">Отель "{{ name }}"</div>
      <div class="order-page__date">{{ checkIn }}-{{ checkOut }}</div>
      <div class="order-page__actions">
        <app-button
          theme="inactive"
          is-square
          is-round
          class="order-page__action"
        >
          <img src="@/assets/icons/SVG/link-external.svg" alt="" />
        </app-button>
        <app-button
          theme="inactive"
          is-square
          is-round
          class="order-page__action"
        >
          <img src="@/assets/icons/SVG/phone-icon.svg" alt="" />
        </app-button>
        <app-button
          theme="inactive"
          is-square
          is-round
          class="order-page__action"
        >
          <img src="@/assets/icons/SVG/mail.svg" alt="" />
        </app-button>
      </div>
    </div>
    <div v-if="orders[0]" class="order-page__inner">
      <div class="order-page__title">Вы заказывали</div>
      <div v-for="(orders, date) in sortedOrders" :key="date">
        <div class="order-page__date">{{ date }}</div>
        <div
          v-for="order in orders"
          :key="order.name + order.date.getTime()"
          class="order-page__item order"
        >
          <div class="order__icon">
            <img src="@/assets/icons/SVG/bed.svg" alt="" />
          </div>
          <div class="order__name">{{ order.name }}. {{ order.count }}. -</div>
          <div :class="['order__cost', { order__cost_free: order.cost === 0 }]">
            {{ order.cost === 0 ? 'Бесплатно' : `${order.cost} р.` }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="order-page__inner">
      <div class="order-page__icon">
        <img
          src="@/assets/icons/SVG/face-frown.svg"
          alt=""
          class="order-page__image"
        />
      </div>
      <div class="order-page__text">Тут пока пусто</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { visits } from '@/config';
  import { Order, Visit } from '@/models';

  const route = useRoute();
  const data = ref(async () => {
    return new Promise<Visit>(resolve => {
      setTimeout(() => {
        resolve(visits[route.params.id]);
      }, 500);
    });
  });

  const name = ref((await data.value()).name);
  const checkIn = ref((await data.value()).checkIn.toLocaleDateString());
  const checkOut = ref((await data.value()).checkOut.toLocaleDateString());
  const orders = ref((await data.value()).orders);

  const groupedOrders = ref(
    (await data.value()).orders.reduce<Record<string, Order[]>>(
      (acc, order) => {
        const date = order.date.toLocaleDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(order);
        return acc;
      },
      {},
    ),
  );

  const sortedOrders = computed(() => {
    return Object.entries(groupedOrders.value)
      .sort(([dateA], [dateB]) => {
        const parsedDateA = Date.parse(dateA.split('.').reverse().join('-'));
        const parsedDateB = Date.parse(dateB.split('.').reverse().join('-'));
        return parsedDateA - parsedDateB;
      })
      .reduce(
        (acc, [date, orders]) => {
          acc[date] = orders;
          return acc;
        },
        {} as Record<string, Order[]>,
      );
  });
</script>

<style lang="scss" scoped>
  .order-page {
    position: relative;

    align-items: center;
    flex-direction: column;

    padding: 30px 0 !important;

    &__block {
      position: absolute;
      top: 0;

      display: flex;
      align-items: center;
      flex-direction: column;

      width: calc(100% + 20px);
      height: 350px;
      padding: 12px;

      border-radius: 0px 0px 30px 30px;
      background-color: white;
      box-shadow: 0px 0px 14.3px 0px #00000021;
    }

    &__cover {
      position: relative;

      width: 100%;
      height: 80px;
      margin-top: 100px;

      border-radius: 20px;
      background: url(@/assets/images/bg.png), var(--blue-bg);
    }

    &__picture {
      position: absolute;
      bottom: -26px;
      left: 50%;

      transform: translateX(-50%);
    }

    &__name {
      margin-top: 30px;

      @include title-text;
    }

    &__date {
      margin: 5px 0 10px;

      color: var(--gray-black);
    }

    &__actions {
      display: flex;

      margin-top: auto;

      gap: 5px;
    }

    &__action {
      img {
        @include filter-blue;
        @include setSquareSize(20px);
      }
    }

    &__title {
      margin: 20px 0 0;

      @include subtitle-text;
    }

    &__inner {
      width: 100%;
      margin-top: 250px;

      .order-page__date {
        margin: 15px 0;
      }
    }

    .order {
      display: flex;
      align-items: center;

      margin-bottom: 5px;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 10px;
        background: var(--blue-bg);

        @include setSquareSize(52px);
      }

      &__name {
        margin-left: 15px;
      }

      &__cost {
        margin-left: 4px;
        &_free {
          padding: 4px 12px;

          color: var(--blue-main);
          border: var(--blue-main) 1px solid;
          border-radius: 23px;
          background: #f9f9ff;
        }
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 50px auto;

      border-radius: 10px;
      background: var(--blue-bg);

      @include setSquareSize(110px);
    }

    &__text {
      text-align: center;

      color: var(--gray-black);

      line-height: 22px;

      @include toRem(font-size, 18px);
    }
  }
</style>
