import { Category, CategoryNode, Hotel } from '@/models';
import api from '@/utils/axios';
import { AxiosResponse } from 'axios';

export default class HotelService {
  static async getHotel(slug: string): Promise<AxiosResponse<Hotel>> {
    return api.get<Hotel>(`/hotel/${slug}`);
  }

  static async getHotelCategories(
    slug: string,
  ): Promise<AxiosResponse<Category[]>> {
    return api.get<Category[]>(`/hotel/service-category/${slug}`);
  }

  static async getCategoryDetails(
    slug: string,
  ): Promise<AxiosResponse<Category>> {
    return api.get<Category>(`/service-category/${slug}`);
  }
}
