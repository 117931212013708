<template>
  <div class="app">
    <div class="container">
      <transition name="popup" mode="out-in" :duration="550">
        <popup-notification
          v-show="isShowPopup"
          :is-error="isOffline"
          :is-correct="!isOffline"
        >
          {{
            isOffline ? 'Соединение потеряно...' : 'Соединение восстановлено!'
          }}
        </popup-notification>
      </transition>
      <router-view v-slot="{ Component, route }">
        <transition name="page" mode="out-in">
          <component :is="Component" :key="route.name" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'App',
  };
</script>

<script lang="ts" setup>
  import { onMounted, onUnmounted, ref } from 'vue';
  import { useAuthStore } from '@/stores/auth';

  const store = useAuthStore();
  const height = ref(0);
  const isShowPopup = ref(false);
  const isOffline = ref(false);
  const timer = ref();

  function setWindowHeight(event?: Event) {
    let vh: number;
    if (!event) vh = window.innerHeight * 0.01;
    else {
      vh = event.target?.height * 0.01;
    }
    return vh;
  }

  function showPopup(value: boolean) {
    clearTimeout(timer.value);
    isShowPopup.value = false;
    timer.value = setTimeout(() => {
      isShowPopup.value = true;
      isOffline.value = value;
    }, 550);
    timer.value = setTimeout(() => {
      isShowPopup.value = false;
    }, 5000);
  }

  onMounted(async () => {
    if (!navigator.onLine) {
      showPopup(true);
    }
    window.addEventListener('offline', () => showPopup(true));
    window.addEventListener('online', () => showPopup(false));
    height.value = setWindowHeight();
    document.documentElement.style.setProperty('--height', `${height.value}px`);
    await store.checkAuth();
  });

  onUnmounted(() => {
    visualViewport?.removeEventListener('resize', setWindowHeight);
  });
</script>

<style lang="scss">
  .app {
    position: relative;

    display: block;
    overflow-x: hidden;

    width: 100vw;
    height: auto;
    min-height: calc(var(--height, 1vh) * 100);

    background: white;

    font: {
      family: 'Proxima Nova', sans-serif;
      weight: 400;
      size: 1rem;
    }
    line-height: 0.9375rem;

    .page-enter-active,
    .page-leave-active {
      transition: opacity 0.15s ease;
    }

    .page-enter-from,
    .page-leave-to {
      opacity: 0;
    }
  }
</style>
