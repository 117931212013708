<template>
  <div class="wrapper order-page">
    <app-header router-link="WelcomePage">История заказов</app-header>
    <div class="order-page__block">
      <div class="order-page__cover skeleton-cover"></div>
      <div class="order-page__name skeleton-text"></div>
      <div class="order-page__date skeleton-text"></div>
      <div class="order-page__actions">
        <div class="order-page__action skeleton-circle"></div>
        <div class="order-page__action skeleton-circle"></div>
        <div class="order-page__action skeleton-circle"></div>
      </div>
    </div>
    <div class="order-page__inner">
      <div class="order-page__title skeleton-text"></div>
      <div class="order-page__date skeleton-text"></div>
      <div class="order-page__item order">
        <div class="order__icon skeleton-circle"></div>
        <div class="order__name skeleton-text"></div>
        <div class="order__cost skeleton-text"></div>
      </div>
      <div class="order-page__item order">
        <div class="order__icon skeleton-circle"></div>
        <div class="order__name skeleton-text"></div>
        <div class="order__cost skeleton-text"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .order-page {
    position: relative;

    align-items: center;
    flex-direction: column;

    padding: 30px 0 !important;

    &__block {
      position: absolute;
      top: 0;

      display: flex;
      align-items: center;
      flex-direction: column;

      width: calc(100% + 20px);
      height: 350px;
      padding: 12px;

      border-radius: 0px 0px 30px 30px;
      background-color: white;
      box-shadow: 0px 0px 14.3px 0px #00000021;
    }

    &__cover.skeleton-cover {
      position: relative;

      width: 100%;
      height: 80px;
      margin-top: 100px;

      animation: pulse 2s infinite;

      border-radius: 20px;
      background-color: var(--blue-bg);
    }

    &__name.skeleton-text,
    &__date.skeleton-text,
    &__title.skeleton-text {
      width: 70%;
      height: 20px;
      margin-top: 30px;

      animation: pulse 2s infinite;

      border-radius: 4px;
      background-color: var(--blue-bg);
    }

    &__date.skeleton-text {
      width: 50%;
      margin: 5px 0 10px;
    }

    &__title.skeleton-text {
      margin-top: 20px;
    }

    &__actions {
      display: flex;

      margin-top: auto;

      gap: 5px;
    }

    &__action.skeleton-circle {
      animation: pulse 2s infinite;

      border-radius: 50%;
      background-color: var(--blue-bg);

      @include setSquareSize(52px);
    }

    &__inner {
      width: 100%;
      margin-top: 250px;

      .order-page__date {
        margin: 15px 0;
      }
    }

    .order {
      display: flex;
      align-items: center;

      margin-bottom: 5px;

      &__icon.skeleton-circle {
        animation: pulse 2s infinite;

        border-radius: 10px;
        background-color: var(--blue-bg);

        @include setSquareSize(52px);
      }

      &__name.skeleton-text,
      &__cost.skeleton-text {
        width: 30%;
        height: 20px;
        margin-left: 15px;

        animation: pulse 2s infinite;

        border-radius: 4px;
        background-color: var(--blue-bg);
      }
    }
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
</style>
